// 登录邮箱
import Cookies from 'js-cookie'
import window from 'window'
import config from '@/config/config.js'
import { Message } from 'element-ui'
const STORAGE_KEY = '__rlA'
const TIME_KEY = '__rlT'

/**
 * @param {string} storageName
 * @returns {boolean}
 */
export function isStorageSupported (storageName) {
    let storageIsAvailable = false
    try {
        // at: window[storageName] firefox throws SecurityError: The operation is insecure. when in iframe
        storageIsAvailable = storageName in window && window[storageName] && window[storageName].setItem
    } catch (e) {} // eslint-disable-line no-empty

    if (storageIsAvailable) {
        const s = window[storageName]
        const key = 'testLocalStorage_' + window.Math.random()

        try {
            s.setItem(key, key)
            if (key === s.getItem(key)) {
                s.removeItem(key)
                return true
            }
        } catch (e) {} // eslint-disable-line no-empty
    }

    return false
}

const SESS_STORAGE = isStorageSupported('sessionStorage') ? window.sessionStorage || null : null
const WIN_STORAGE = window.top || window || null

const __get = (key) => {
    let result = null
    if (SESS_STORAGE) {
        result = SESS_STORAGE.getItem(key) || null
    } else if (WIN_STORAGE && window.JSON) {
        const data = WIN_STORAGE.name && WIN_STORAGE.name.toString().substr(0, 1) === '{' ? window.JSON.parse(WIN_STORAGE.name.toString()) : null
        result = data ? data[key] || null : null
    }

    return result
}

const __set = (key, value) => {
    if (SESS_STORAGE) {
        SESS_STORAGE.setItem(key, value)
    } else if (WIN_STORAGE && window.JSON) {
        let data = WIN_STORAGE.name && WIN_STORAGE.name.toString().substr(0, 1) === '{' ? window.JSON.parse(WIN_STORAGE.name.toString()) : null
        data = data || {}
        data[key] = value

        WIN_STORAGE.name = window.JSON.stringify(data)
    }
}

const timestamp = () => window.Math.round(new window.Date().getTime() / 1000)

const setTimestamp = () => __set(TIME_KEY, timestamp())

const getTimestamp = () => {
    const time = __get(TIME_KEY, 0)
    return time ? window.parseInt(time, 10) || 0 : 0
}

/**
 * @returns {string}
 */
export function getHash () {
    return __get(STORAGE_KEY)
}

/**
 * @returns {void}
 */
export function setHash () {
    const key = 'AuthAccountHash'
    const appData = window.__rlah_data()

    __set(STORAGE_KEY, appData && appData[key] ? appData[key] : '')
    setTimestamp()
}

/**
 * @returns {void}
 */
export function clearHash () {
    __set(STORAGE_KEY, '')
    setTimestamp()
}

/**
 * @returns {boolean}
 */
export function checkTimestamp () {
    if (timestamp() > getTimestamp() + 1000 * 60 * 60) {
        // 60m
        clearHash()
        return true
    }
    return false
}

// 跳转至客户端
export function JumpMailClient (token_rl) {
    if (token_rl.rlspecauth) {
        __set(STORAGE_KEY, token_rl.rlspecauth)
        Cookies.set('rlspecauth', token_rl.rlspecauth, { expires: 7 })
    }
    if (token_rl.rlsmauth) {
        Cookies.set('rlsmauth', token_rl.rlsmauth, { expires: 7 })
    }

    setTimeout(() => {
        location.href = config.RL_URL
    }, 200)
}

// 退出
export function Logout () {
    Cookies.remove(config.COOKIES.LOGIN_MAIL)
    Cookies.remove(config.COOKIES.TOKEN_MAIL)
    Cookies.remove(config.COOKIES.TOKEN_USER)
    Cookies.remove(config.COOKIES.CLIENT_API_URL)
    Cookies.remove('rlsmauth')
    Cookies.remove('rlspecauth')
}

// 初始化API地址
export function InitApiURL (mail_type = '', api_url = '') {
    if (process.env.NODE_ENV === 'development') {
        Cookies.set(config.COOKIES.CLIENT_API_URL, 'http://localhost:9812', { expires: 7 })
        return
    }
    if (api_url === '' || (mail_type !== 'domain_mail' && mail_type !== 'random_mail')) {
        Message.error(this.$t('wu-fa-huo-qu-qing-qiu-di-zhi'))
        return
    }
    Cookies.set(config.COOKIES.CLIENT_API_URL, api_url, { expires: 7 })
}
