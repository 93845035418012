import request from '@/utils/request'

export function GetMailDetailByTokenMail (data) {
    return request({
        path: '/v1/client/mail/detail/by-token-mail',
        params: data
    })
}

export function GetClientUserDetail (data) {
    return request({
        path: '/v1/client/user/detail',
        params: data
    })
}

export function Logout (data) {
    return request({
        method: 'post',
        params: { ...data, action: 'logout' }
    })
}

export function CheckMailDetail (data) {
    return request({
        path: '/v1/client/mail/check',
        params: data
    })
}

export function GetMailVerifyStatus (data) {
    return request({
        path: '/v1/client/mail/verify-status',
        params: data
    })
}

// 通过主token登录
export function LoginMailByToken (data) {
    return request({
        method: 'post',
        path: '/v1/client/mail/login/by-token',
        params: data
    })
}

// 邮箱密码登录
export function LoginMailByMail (data) {
    return request({
        method: 'post',
        path: '/v1/client/mail/login/by-mail',
        params: data
    })
}
